body {
  font-family: "Poppins", sans-serif !important;
  /* background-color: #1f2630 !important; */
  font-style: inherit;
  font-weight: inherit;
  font-display: swap;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 10px;
}

/* Smooth scrolling */
html {
  scroll-behavior: smoothy;
}

.icon-active svg path {
  fill: #25346a;
}

.dark .icon-active svg path {
  fill: #e5b910 !important;
}

.icon-nav svg {
  width: 20px;
  height: 20px;
}
.nav-group,
.nav-view-more {
  transition: background-color 0.7s ease-in;
}

.nav-group .icon-nav svg path,
.nav-view-more .icon-nav svg path {
  transition: fill 0.7s ease-in;
}

.nav-group:hover .icon-nav svg path,
.nav-view-more:hover .icon-nav svg path {
  fill: #25346a;
}

.dark .nav-group:hover .icon-nav svg path,
.dark .nav-view-more:hover .icon-nav svg path {
  fill: #e5b910;
}

.nav-group .nav-title,
.nav-view-more .nav-title {
  transition: all 0.6s ease-in;
}
.nav-group:hover .nav-title,
.nav-view-more:hover .nav-title {
  font-weight: 600;
  color: #25346a;
}
.dark .nav-group:hover .nav-title,
.dark .nav-view-more:hover .nav-title {
  font-weight: 600;
  color: #fff;
}
.dark .icon-nav svg path,
.dark .dark-arrow-gray path {
  fill: #6b6b6b;
}
.dark .dark-arrow path,
.dark .dark-close path,
.dark .dark-menu path,
.dark .dark-logo path:not(:nth-last-child(-n + 3)) {
  fill: #fff;
}

.dark .dark-light-icon path {
  fill: #e5b910;
}

.dark .dark-pin {
  fill: #25346a;
}

/* Wallet button icons */
.wallet-icons svg {
  width: 15px;
  height: 15px;
}

.wallet-btn:hover svg path {
  fill: #fff;
}

/* table */

table {
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1.5rem;
  margin-top: 1.875rem;
  white-space: wrap;
  position: relative;
}

.dark table {
  background-color: #29313c;
}

.table th,
.table td {
  padding: 0.875rem 1.34rem;
  border: none;
  color: #6b6b6b;
  position: relative;
}

.dark .table th,
.dark .table td {
  color: #c4c4c4;
}

.table-modal th,
.table-modal td {
  padding: 0.875rem 0.8rem;
  position: relative;
}

.table thead {
  background: #f4f4f4;
}
.dark .table thead {
  background: #1f2630;
}
.table tbody {
  width: 100%;
}

.table th {
  font-weight: 600;
  font-size: 0.6rem;
  line-height: 1.4;
  text-align: left;
}

.table td {
  font-size: 0.6rem;
  line-height: 1.4;
  overflow-wrap: break-word;
}

.table tr:not(:has(th)):not(:nth-child(2n)) {
  padding: 2px 0;
}

.table tr:nth-child(2n) {
  background: #c4c4c41a;
  border-bottom: 1px solid #efefef;
}

.table thead {
  display: table-header-group;
}

@media (max-width: 767px) {
  .table tr {
    display: flex;
    flex-direction: column !important;
    height: 100px;
    flex-wrap: wrap;
  }

  .table tr td:nth-child(n + 5) {
    align-self: flex-end;
  }

  .table thead {
    display: none;
  }

  .table td {
    padding: 0.5rem;
  }
}

.balance {
  background-image: url("/src/assets/icons/wallet/wallet-bg.svg");
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("/src/assets/icons/wallet/wallet-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 35% auto;
}

.dark .balance {
  background-image: url("/src/assets/icons/wallet/wallet-bg.svg");
  background-image: linear-gradient(
      rgba(41, 49, 60, 0.879),
      rgba(41, 49, 60, 0.879)
    ),
    url("/src/assets/icons/wallet/wallet-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 35% auto;
}

.appeal {
  background-image: url("/src/assets/icons/wallet/wicrypt-appeal-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 20% auto;
}

/* QR code background */
.QRcode-bg {
  position: relative;
  border: 3px solid #8f92a161;
  border-radius: 12px;
  background: #fff;
  transition: all 0.05s linear;
}

.dark .QRcode-bg {
  background: #29313c;
  border: 3px solid #8f92a1;
}

.QRcode-bg:before,
.QRcode-bg:after {
  content: "";
  position: absolute;
  background: #fff;
  transition: all 0.2s linear;
}
.dark .QRcode-bg:before,
.dark .QRcode-bg:after {
  background: #29313c;
}

.QRcode-bg:before {
  width: calc(100% + 9px);
  height: calc(100% - 64px);
  top: 32px;
  left: -4px;
}

.QRcode-bg:after {
  height: calc(100% + 9px);
  width: calc(100% - 64px);
  top: -3px;
  left: 32px;
}

/* checkbox styling */
.material-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.material-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.checkmark {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 12px;
  border: 2px solid #b2b2b2;
  border-radius: 50%;
  transition: all 0.3s;
  margin-left: auto;
}

.material-checkbox input[type="checkbox"]:checked ~ .checkmark {
  /* background-color: #fff; */
  border-color: #cfa809;
}

.material-checkbox input[type="checkbox"]:checked ~ .checkmark:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 3.2px;
  width: 4px;
  height: 7px;
  border: solid #cfa809;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.material-checkbox input[type="checkbox"]:focus ~ .checkmark {
  box-shadow: 0 0 0 2px #e4f07d65;
}

.material-checkbox:hover input[type="checkbox"] ~ .checkmark {
  border-color: #caab30;
}

.material-checkbox input[type="checkbox"]:disabled ~ .checkmark {
  opacity: 0.5;
  cursor: not-allowed;
}

.material-checkbox input[type="checkbox"]:disabled ~ .checkmark:hover {
  border-color: #4d4d4d;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/* Explorer */
.explorer-icon svg {
  width: 10px;
  height: 10px;
}
.explorer-icon.green svg path {
  fill: #db5f5f;
}

.explorer-icon.red svg path {
  fill: #db5f5f;
}

/* Explorer rating star */
.star svg {
  width: 15px;
  height: 15px;
}
@media (min-width: 767px) {
  .star svg {
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1200px) {
  .star svg {
    width: 30px;
    height: 30px;
  }
}
.rated path {
  fill: #cfa809;
}
.unrated path {
  fill: #aeaead;
}

.rating:not(:checked) > input {
  position: absolute;
  appearance: none;
}

/* .rating:not(:checked) > label {
  cursor: pointer;
  font-size: 25px;
  color: #666;
} */

@media (min-width: 767px) {
  .rating:not(:checked) > label {
    font-size: 40px;
  }
}

.rating:not(:checked) > label:before {
  content: "★";
}

/* .rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #cfa809;
} */

.rating > input:checked ~ label {
  color: #cfa809;
}

/* .rating {
  direction: rtl;
} */

/* radio button color */

.radio-input {
  align-items: center;
  justify-content: center;
}

.radio-input input {
  appearance: none;
  width: 1em;
  height: 1em;
  background-color: transparent;
  border-radius: 50%;
  transition: 0.4s ease-in-out;
  /* box-shadow: inset 2px 5px 10px rgb(255, 255, 255); */
  border: 1px solid #e5b910;
}

.radio-input input:hover {
  scale: 1.2;
  cursor: pointer;
  box-shadow: none;
}

.radio-input .circle {
  position: relative;
  top: 0.01em;
  left: -1.8em;
  width: 0.6em;
  height: 0.6em;
  background-color: #e5b910;
  scale: 0;
  border-radius: 10px;
  transition: 0.4s ease-in-out;
}

.radio-input input:checked {
  box-shadow: none;
  border: 1px solid #e5b910;
}

.radio-input input:checked + .circle {
  /* transform: rotate(360deg); */
  scale: 1;
}

/* onboarding indicator */

.indicators-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.indicator-button {
  border: none;
  background-color: #ccc;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
}

.indicator-button.active {
  background-color: #007bff;
}

/* React Joyride */
.__floater .__floater__body .react-joyride__tooltip {
  color: #fff !important;
}

/* Profile background */
.profile-bg {
  background-image: none;
}
.second-profile-bg {
  background-image: url("/src/assets/icons/wallet/wallet-bg.svg");
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("/src/assets/icons/wallet/wallet-bg.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 45% auto;
}
.dark .second-profile-bg {
  background-image: url("/src/assets/icons/wallet/wallet-bg.svg");
  background-image: linear-gradient(
      rgba(41, 49, 60, 0.879),
      rgba(41, 49, 60, 0.879)
    ),
    url("/src/assets/icons/wallet/wallet-bg.svg");
}
.dark .profile-bg {
  background-image: none;
}

@media (min-width: 330px) {
  .second-profile-bg {
    background-size: 35% auto;
  }
}
@media (min-width: 765px) {
  .profile-bg {
    background-image: url("/src/assets/icons/wallet/wallet-bg.svg");
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      url("/src/assets/icons/wallet/wallet-bg.svg");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: auto;
  }
  .dark .profile-bg {
    background-image: url("/src/assets/icons/wallet/wallet-bg.svg");
    background-image: linear-gradient(
        rgba(41, 49, 60, 0.879),
        rgba(41, 49, 60, 0.879)
      ),
      url("/src/assets/icons/wallet/wallet-bg.svg");
  }
  .second-profile-bg {
    background-image: none;
  }
  .dark .second-profile-bg {
    background-image: none;
  }
}

/* Toggle switch */

.md_switch {
  display: inline-flex;
  align-items: center;
}
.md_switch .md_switch__toggle {
  position: relative;
  cursor: pointer;
}
.md_switch .md_switch__toggle::before,
.md_switch .md_switch__toggle::after {
  content: "";
  margin: 0 3px;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
}
.md_switch .md_switch__toggle::before {
  height: 1.1em;
  width: 2.5em;
  background: #e8e8e8;
  border-radius: 0.85em;
}
.md_switch .md_switch__toggle::after {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0, -50%);
  height: 0.7em;
  width: 0.7em;
  border-radius: 50%;
  background: #6b6b6b;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0 0 2px rgba(0, 0, 0, 0.4);
}
.md_switch [type="checkbox"]:checked + .md_switch__toggle::before {
  background: #25346a;
  opacity: 0.5;
}
.md_switch [type="checkbox"]:checked + .md_switch__toggle::after {
  background: #25346a;
  box-shadow: 0 0 8px rgba(37, 52, 106, 0.2), 0 0 2px rgba(37, 52, 106, 0.4);
}
.dark .md_switch [type="checkbox"]:checked + .md_switch__toggle::before {
  background: #f5f5f5;
  opacity: 1;
}
.dark .md_switch [type="checkbox"]:checked + .md_switch__toggle::after {
  background: #c4c4c4;
  box-shadow: 0 0 8px rgba(37, 52, 106, 0.2), 0 0 2px rgba(37, 52, 106, 0.4);
}
.md_switch [type="checkbox"]:checked + .md_switch__toggle::after {
  transform: translate(calc(2em - 100%), -50%);
}
.md_switch [type="checkbox"] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

/* Tooltip */

/* Show the tooltip when the 'show' class is present */
.tooltip.show {
  display: block;
}

/* Hide the tooltip by default */
.tooltip.hidden {
  display: none;
}

.star-active svg path {
  stroke: #e5b910;
  fill: #e5b910;
}

/* Notify phone color change on dark mode */
.dark .dark-phone path {
  fill: #6b6b6b;
}

/* Aspect ration on picture */
.pic-aspect {
  aspect-ratio: 1;
}

.switch {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 24px;
  cursor: pointer;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: #6B6B6B;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #A9BAF3;
}

input:checked + .slider:before {
  background-color: #25346A;
}
input:focus + .slider {
  box-shadow: 0 0 1px #A9BAF3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.tab-container{
  padding: 3.934px 0px 3.052px 4.774px;
  border-radius: 20.181px;
}

.tab-option{
  padding: 11.643px 18.629px;
  border-radius: 20.181px;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
}

.search-container{
  width: 167px;
  height: 40px;
}

/* wicrypt Toggle switch */

.wicrypt_switch {
  display: inline-flex;
  align-items: center;
}
.wicrypt_switch .wicrypt_switch__toggle {
  position: relative;
  cursor: pointer;
}
.wicrypt_switch .wicrypt_switch__toggle::before,
.wicrypt_switch .wicrypt_switch__toggle::after {
  content: "";
  margin: 0 3px;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
}
.wicrypt_switch .wicrypt_switch__toggle::before {
  height: 0.7em;
  width: 1.6em;
  background: #E5B910;
  opacity: 40%;
  border-radius: 0.85em;
}
.wicrypt_switch .wicrypt_switch__toggle::after {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0, -50%);
  height: 0.5em;
  width: 0.5em;
  border-radius: 50%;
  background: #E5B910;
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0 0 2px rgba(0, 0, 0, 0.4); */
}
.wicrypt_switch [type="checkbox"]:checked + .wicrypt_switch__toggle::before {
  background: #E5B910;
  opacity: 0.5;
}
.wicrypt_switch [type="checkbox"]:checked + .wicrypt_switch__toggle::after {
  background: #E5B910;
  /* box-shadow: 0 0 8px rgba(37, 52, 106, 0.2), 0 0 2px rgba(37, 52, 106, 0.4); */
}
.wicrypt_switch [type="checkbox"]:checked + .wicrypt_switch__toggle::after {
  transform: translate(calc(1.2em - 100%), -50%);
}
.wicrypt_switch [type="checkbox"] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.dark {
  --default-text-color: #FFFFFF;
}
